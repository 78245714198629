import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import data from "./ProjectData";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Projects = () => {
	const containerRef = useRef(null);

	useEffect(() => {
		const container = containerRef.current;
		const handleScroll = (event) => {
			const scrollAmount = event.deltaY;
			if (scrollAmount !== 0) {
				container.scrollLeft += scrollAmount * 0.9;
				event.preventDefault();
			}
		};
		container.addEventListener("wheel", handleScroll, { passive: false });
		return () => {
			container.removeEventListener("wheel", handleScroll);
		};
	}, []);

	return (
		<Div ref={containerRef}>
			{data.map((projects) => {
				return (
					<Container>
						<h3>
							<Span>{projects.num}</Span> {projects.title}{" "}
							<LinkTag
								href={projects.link}
								target="_blank"
								rel="noopener noreferrer"
							>
								↗
							</LinkTag>
						</h3>

						<div>
							{Array.isArray(projects.images) &&
								projects.images.map((image, index) => (
									<div key={index}>
										<ProjectImage src={image.url} alt={image.altText} />
									</div>
								))}
						</div>
					</Container>
				);
			})}
		</Div>
	);
};

export default Projects;

const Div = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 0 1rem;
	width: 100%;
	height: 100%;
	overflow-x: scroll;
	white-space: nowrap;
	color: var(--text-100);
`;

const Container = styled.div`
	border: solid var(--accent-100) 1px;
	border-radius: 0.4rem;
	height: 60vh;
	width: 85vw;
	padding: 2rem;
	margin: 2rem;
	font-family: "Nunito", sans-serif;
`;

const Span = styled.span`
	font-size: 2vw;
	color: var(--text-200);
`;

const LinkTag = styled.a`
	font-size: 2vw;
`;

const ProjectImage = styled.img`
	height: 15vh;
`;
