import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

:root {
  --custom-highlight-color: #eaded2; 
  /* burlap */
  --text-100: #E3DFD3;
  /* cinnamon stick */
  --text-200: #885F45;
  /* coconut flour */
  --text-300: #E3DFD3;
  /* olive branch */
  --accent-100: #5C5445;
  /* slate */
  --accent-200: #2F2C2B ;
}

::selection {
  background-color: var(--custom-highlight-color);
  color: #000814; 
 
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  height: 100vh;
  width: 100vw;
}


a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
 }
`;

export default GlobalStyles;
