import React from "react";
import styled from "styled-components";

const Footer = () => {
	return (
		<FooterContainer>
			<p>Built and Designed by Iqra</p>
			<p>©2023 </p>
		</FooterContainer>
	);
};

export default Footer;

const FooterContainer = styled.footer`
	position: absolute;
	bottom: 3rem;
	color: white;
	display: flex;
	justify-content: space-between;
	width: calc(100vw - 6rem);
	margin: 1rem;

	p {
		font-size: 0.7rem;
		color: var(--accent-100);
	}
`;
