import React from "react";
import styled from "styled-components";

const About = () => {

	return (
		<Div>
			<div>
				<h1>ABOUT ME</h1>
			</div>
		</Div>
	);
};

export default About;

const Div = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 4rem);
	width: calc(100vw - 4rem);
	position: absolute;
	top: 0;
	margin: 0 2rem;
	box-sizing: border-box;
	border-radius: 0.3rem;
	background-color: transparent;
	color: white;
`;
